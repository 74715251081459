<template>
  <CSidebar aside :show.sync="Toggle" :overlaid="true" colorScheme="light" class="shadow" @update:show="HandleToggle">
    <CSidebarNav>
      <CSidebarNavTitle>{{ $t('Order.PrintShipmentList') }}</CSidebarNavTitle>
      <CListGroup :flush="true" accent>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.PrintShipmentList()">
          {{ $t('Order.PrintShipmentListOption.ExportSelect') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.PrintShipmentList(true)">
          {{ $t('Order.PrintShipmentListOption.ExportFilterCondition') }}
        </CListGroupItem>
      </CListGroup>
      <CSidebarNavTitle>{{ $t('Order.BulkCreateOrder') }}</CSidebarNavTitle>
      <CListGroup :flush="true" accent>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkCreateShipmentOrder('ecpay-logistics')">
          {{ $t('Logistics.LogisticsSystem.ECPAY-LOGISTICS') }}
        </CListGroupItem>
        <!--        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkCreateShipmentOrder('ecpay-logistics', true)">-->
        <!--          {{ $t('Logistics.LogisticsSystem.ECPAY-LOGISTICS') }}-{{ $t('Order.BulkCreateOrderFilterCondition') }}-->
        <!--        </CListGroupItem>-->
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkCreateShipmentOrder('hct-logistics')">
          {{ $t('Logistics.LogisticsSystem.HCT-LOGISTICS') }}
        </CListGroupItem>
<!--        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkCreateShipmentOrder('hct-logistics', true)">-->
<!--          {{ $t('Logistics.LogisticsSystem.HCT-LOGISTICS') }}-{{ $t('Order.BulkCreateOrderFilterCondition') }}-->
<!--        </CListGroupItem>-->
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkCreateShipmentOrder('sf-logistics')">
          {{ $t('Logistics.LogisticsSystem.SF-LOGISTICS') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkCreateShipmentOrder('kerrytj-logistics')">
          {{ $t('Logistics.LogisticsSystem.KERRYTJ-LOGISTICS') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkCreateShipmentOrder('dpex-logistics')">
          {{ $t('Logistics.LogisticsSystem.DPEX-LOGISTICS') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkCreateShipmentOrder('ucfuship-logistics')">
          {{ $t('Logistics.LogisticsSystem.UCFUSHIP-LOGISTICS') }}
        </CListGroupItem>
<!--        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkCreateShipmentOrder('ucfuship-logistics', true)">-->
<!--          {{ $t('Logistics.LogisticsSystem.UCFUSHIP-LOGISTICS') }}-{{ $t('Order.BulkCreateOrderFilterCondition') }}-->
<!--        </CListGroupItem>-->
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkCreateShipmentOrder('jenjan-logistics')">
          {{ $t('Logistics.LogisticsSystem.JENJAN-LOGISTICS') }}
        </CListGroupItem>
<!--        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkCreateShipmentOrder('jenjan-logistics', true)">-->
<!--          {{ $t('Logistics.LogisticsSystem.JENJAN-LOGISTICS') }}-{{ $t('Order.BulkCreateOrderFilterCondition') }}-->
<!--        </CListGroupItem>-->
      </CListGroup>
      <CSidebarNavTitle>{{ $t('Order.PrintLabel') }}</CSidebarNavTitle>
      <CListGroup :flush="true" accent>
        <!--        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.PrintShipmentList(true)">-->
        <!--          {{ $t('Order.BulkCreateOrderOptions.ECPAY-LOGISTICS') }}-->
        <!--        </CListGroupItem>-->
        <!--        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.PrintShipmentList(true)">-->
        <!--          {{ $t('Order.BulkCreateOrderOptions.UCFUSHIP-LOGISTICS') }}-->
        <!--        </CListGroupItem>-->
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkPrintLabelModal('ecpay-logistics')">
          <i class="fas fa-info-circle mr-2" v-c-tooltip="{content: $t('Order.BulkPrintLabelFilterInfo2'), placement: 'right'}" />{{ $t('Logistics.LogisticsSystem.ECPAY-LOGISTICS') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkPrintLabel('hct-logistics')">
          <i class="fas fa-info-circle mr-2" v-c-tooltip="{content: $t('Order.BulkPrintLabelFilterInfo2'), placement: 'right'}" />{{ $t('Logistics.LogisticsSystem.HCT-LOGISTICS') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkPrintLabelModal('kerrytj-logistics')">
          <i class="fas fa-info-circle mr-2" v-c-tooltip="{content: $t('Order.BulkPrintLabelFilterInfo2'), placement: 'right'}" />{{ $t('Logistics.LogisticsSystem.KERRYTJ-LOGISTICS') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkPrintLabelModal('sf-logistics')">
          <i class="fas fa-info-circle mr-2" v-c-tooltip="{content: $t('Order.BulkPrintLabelFilterInfo2'), placement: 'right'}" />{{ $t('Logistics.LogisticsSystem.SF-LOGISTICS') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkPrintLabelModal('ucfuship-logistics')">
          <i class="fas fa-info-circle mr-2" v-c-tooltip="{content: $t('Order.BulkPrintLabelFilterInfo2'), placement: 'right'}" />{{ $t('Logistics.LogisticsSystem.UCFUSHIP-LOGISTICS') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkPrintLabelModal('dpex-logistics')">
          <i class="fas fa-info-circle mr-2" v-c-tooltip="{content: $t('Order.BulkPrintLabelFilterInfo2'), placement: 'right'}" />{{ $t('Logistics.LogisticsSystem.DPEX-LOGISTICS') }}
        </CListGroupItem>
<!--        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.RedirectPrintShipmentLabel('ecpay-logistics', 'UNIMARTC2C')">-->
<!--          <i class="fas fa-info-circle mr-2" v-c-tooltip="{content: $t('Order.BulkPrintLabelFilterInfo'), placement: 'right'}" />{{ $t('Logistics.LogisticsSystem.ECPAY-LOGISTICS') }}-{{ $t('Logistics.LogisticsMethods.UNIMARTC2C') }}-->
<!--        </CListGroupItem>-->
<!--        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.RedirectPrintShipmentLabel('ecpay-logistics', 'FAMIC2C')">-->
<!--          <i class="fas fa-info-circle mr-2" v-c-tooltip="{content: $t('Order.BulkPrintLabelFilterInfo'), placement: 'right'}" />{{ $t('Logistics.LogisticsSystem.ECPAY-LOGISTICS') }}-{{ $t('Logistics.LogisticsMethods.FAMIC2C') }}-->
<!--        </CListGroupItem>-->
<!--        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.RedirectPrintShipmentLabel('ecpay-logistics', 'OKMARTC2C')">-->
<!--          <i class="fas fa-info-circle mr-2" v-c-tooltip="{content: $t('Order.BulkPrintLabelFilterInfo'), placement: 'right'}" />{{ $t('Logistics.LogisticsSystem.ECPAY-LOGISTICS') }}-{{ $t('Logistics.LogisticsMethods.OKMARTC2C') }}-->
<!--        </CListGroupItem>-->
      </CListGroup>
      <CSidebarNavTitle>{{ $t('Order.LogisticsTracking.BulkImport') }}</CSidebarNavTitle>
      <CListGroup :flush="true" accent>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.BulkImportLogisticsData()">
          {{ $t('Order.LogisticsTracking.BulkImport') }}
        </CListGroupItem>
      </CListGroup>
      <CSidebarNavTitle>{{ $t('Order.ExportLogisticsList.Title') }}</CSidebarNavTitle>
      <CListGroup :flush="true" accent>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.ExportLogisticsList('TCAT_CLIENT')">
          {{ $t('Order.ExportLogisticsList.TCAT_CLIENT') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.ExportLogisticsList('TCAT_CLIENT_NEW')">
          {{ $t('Order.ExportLogisticsList.TCAT_CLIENT_NEW') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.ExportLogisticsList('TCAT')">
          {{ $t('Order.ExportLogisticsList.TCAT') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.ExportLogisticsList('KERRYTJ')">
          {{ $t('Order.ExportLogisticsList.KERRYTJ') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.ExportLogisticsList('HCT')">
          {{ $t('Order.ExportLogisticsList.HCT') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.ExportLogisticsList('DPEX')">
          {{ $t('Order.ExportLogisticsList.DPEX') }}
        </CListGroupItem>
        <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.ExportLogisticsList('JENJAN')">
          {{ $t('Order.ExportLogisticsList.JENJAN') }}
        </CListGroupItem>
      </CListGroup>
      <CSidebarNavTitle>{{ $t('Order.OtherAction') }}</CSidebarNavTitle>
      <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.TransReport('hct-logistics')">
        {{ $t('Logistics.LogisticsSystem.HCT-LOGISTICS') }}-{{ $t('Order.TransReport') }}
      </CListGroupItem>
      <CListGroupItem href="#" color="secondary" accent="secondary" @click="$parent.TransReport('kerrytj-logistics')">
        {{ $t('Logistics.LogisticsSystem.KERRYTJ-LOGISTICS') }}-{{ $t('Order.TransReport') }}
      </CListGroupItem>
    </CSidebarNav>
  </CSidebar>
</template>

<script>
export default {
  props: ['Toggle'],
  name: 'BulkActionSidebar',
  data () {
    return {
    }
  },
  mounted() {
  },
  methods: {
    HandleToggle(value) {
      this.$emit('update:Toggle', value)
    }
  }
}
</script>

<style>
.c-sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  width: 100vw;
  height: 100vh;
  background-color: #000015;
  transition: 0.3s;
}
.c-sidebar-backdrop.c-show {
  opacity: 0.5;
}
</style>
